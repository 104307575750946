<template>
  <div>
    <!-- <OfflineView /> -->
    <CategoryHeading :category="category" />
    <div class="bg-lightt">
      <Breadcrumb id="CategoryView" :category="category" :meta="meta" />
      <CategoryProductListing
        style="min-height: 50vh"
        :loading="loading"
        :pageCount="2"
        :products="products"
      />
      <div class="container pb-5">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center pb-5">
              <Pagination
                @pageChange="handlePageChange"
                :totalRecord="pagination.totalRecords"
                :currentPage="pagination.page"
                :perPage="pagination.per_page"
                :loading="loading"
              />
              <!-- <button
              v-if="moreContentAvailable"
              @click="incrementPage()"
              :disabled="moreLoading"
              class="btn btn-imp-secondary"
            >
              Load more products

              <BtnLoading v-if="moreLoading" class="btn-loading" />
            </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <MoreProducts title="RECOMMENDED FOR YOU" :data="[]" /> -->
  </div>
</template>
<script>
import CategoryHeading from "@/components/CategoryHeading.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import CategoryProductListing from "@/components/CategoryProductListing.vue";
import MoreProducts from "@/components/MoreProducts.vue";
import Pagination from "@/components/Pagination.vue";
import OfflineView from "../components/OfflineView.vue";

export default {
  components: {
    CategoryHeading,
    Breadcrumb,
    CategoryProductListing,
    MoreProducts,
    Pagination,
    OfflineView,
  },
  data() {
    return {
      loading: true,
      moreLoading: false,
      current_page: 1,
      products: [],
      meta: {},
      pagination: {},
    };
  },
  computed: {
    category() {
      return this.$store.getters.categorySelect;
    },
    filters() {
      return this.$store.getters.filters;
    },
    moreContentAvailable() {
      if (
        Math.floor(this.meta.totalRecords / this.meta.per_page) >=
        this.current_page
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    filters: {
      handler() {
        // console.log(this.filters);
        this.products = [];
        this.loading = true;
        this.current_page = 1;
        // console.log("Change");
        this.getProductsbyCategory();
      },
      deep: true,
    },
    category: function () {
      this.products = [];
      this.loading = true;
      this.current_page = 1;
      this.getProductsbyCategory();
    },
    current_page: function () {
      this.getProductsbyCategory(true);
    },
  },
  mounted() {
    this.getBl();
  },
  methods: {
    incrementPage() {
      this.current_page = this.meta.page++;
    },
    getBl() {
      this.loading = true;
      this.getProductsbyCategory(false);
    },
    handlePageChange(page) {
      this.loading = true;
      document.querySelector("#CategoryView").scrollIntoView();
      this.current_page = page;
    },
    getProductsbyCategory(divLoad) {
      if (divLoad) {
        this.moreLoading = true;
      }

      let categ = this.category;
      if (categ == "all") {
        categ = "";
      }
      let payload = {
        path: `product?category=${categ}&min_price=${this.filters.minPrice}&max_price=${this.filters.maxPrice}&min_capacity=${this.filters.minCapacity}&max_capacity=${this.filters.maxCapacity}&delivery_location=${this.filters.deliveryLocation}&page=${this.current_page}&per_page=20`,
      };
      // console.log(payload.path);
      this.$store
        .dispatch("getRequest", payload)
        .then((resp) => {
          // console.log(resp.data.data);
          resp.data = JSON.parse(this.$helpers.decrypt(resp.data.data));
          this.loading = false;
          // this.products = this.products.concat(resp.data.data.result);
          this.products = resp.data.data.result;
          // console.log(this.products);
          this.pagination = resp.data.data;
          this.meta = resp.data.data;
          this.moreLoading = false;
        })
        .catch((err) => {
          this.moreLoading = false;
          this.loading = false;
        });
    },
  },
};
</script>
