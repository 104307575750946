<template>
  <div class="text-center categ-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-3">
          <img class="cat-img" :src="categoryImage" alt="" />
        </div>
        <div class="col-md-6">
          <h1 class="categoryHeadd f-bold text-capitalize">
            {{ categoryData }}
          </h1>
          <p class="mb-0 text-dark">
            Gain access to various renewable energy solutions and energy saving
            equipments
            <!-- <span class="text-capitalize">{{ categoryData }} </span> -->
          </p>
          <!-- <p class="mb-0 text-dark">Shop your choice of product!</p> -->
          <div class="category-select">
            <span>Category</span>
            <select
              @change="selectChange()"
              v-model="categorySelect"
              class="form-control"
              name=""
              id=""
            >
              <option value="">All categories</option>
              <option value="solar panel">Solar Panel</option>
              <option value="inverter">Inverter Solutions</option>
              <option value="battery">Battery</option>
              <option value="accessory">Accessories</option>
              <option value="home equipment">Home Equipments</option>
              <option value="bundle">Complete Solution</option>
              <option value="package">Special offers</option>
              <!-- <option value="preowned">Pre Owned</option> -->
            </select>
          </div>
        </div>
        <div v-if="!isFilter" class="col-12 openBtn">
          <span class="cl" @click="openFilter">Show Filters</span>
        </div>
        <div class="col-12" id="Filters">
          <div class="col-12 filters" v-if="isFilter">
            <div class="close w-100">
              <span @click="closeFilter" class="cl">hide</span>
            </div>
            <div class="row">
              <!-- <div class="col-12 col-md-3">
                <label for="">Category</label>
                <br />
                <select
                  @change="selectChange()"
                  v-model="categorySelect"
                  class="form-control"
                  name=""
                  id=""
                >
                  <option value="">All categories</option>
                  <option value="solar panel">Solar Panel</option>
                  <option value="inverter">Inverter Solutions</option>
                  <option value="battery">Battery</option>
                  <option value="accessory">Accessories</option>
                  <option value="home equipment">Home Equipments</option>
                  <option value="bundle">Complete Solution</option>
                  <option value="package">Special offers</option>
                  !-- <option value="preowned">Pre Owned</option> --
                </select>
              </div> -->
              <div class="col-md-4 col-12">
                <div class="row">
                  <div class="col-6">
                    <label for="">Min Price</label>
                    <input
                      class="form-control"
                      type="number"
                      v-model="minPrice"
                      @change="addToFilters"
                      name=""
                      id=""
                    />
                  </div>
                  <div class="col-6">
                    <label for="">Max Price</label>
                    <input
                      class="form-control"
                      type="number"
                      v-model="maxPrice"
                      @change="addToFilters"
                      name=""
                      id=""
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="row">
                  <div class="col-6">
                    <label for="">Min Capacity</label>
                    <select
                      class="form-control"
                      v-model="filters.minCapacity"
                      name=""
                      id=""
                    >
                      <option value="">None</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="1.5">1.5</option>
                      <option value="2">2</option>
                      <option value="2.5">2.5</option>
                      <option value="3">3</option>
                      <option value="3.5">3.5</option>
                      <option value="4">4</option>
                      <option value="4.5">4.5</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <label for="">Max Capacity</label>
                    <select
                      class="form-control"
                      v-model="filters.maxCapacity"
                      name=""
                      id=""
                    >
                      <option value="">None</option>
                      <!-- <option value="0">0</option> -->
                      <option value="1">1</option>
                      <option value="1.5">1.5</option>
                      <option value="2">2</option>
                      <option value="2.5">2.5</option>
                      <option value="3">3</option>
                      <option value="3.5">3.5</option>
                      <option value="4">4</option>
                      <option value="4.5">4.5</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="row">
                  <!-- <div class="col-6"> -->
                  <label for="">Delivery Location</label>
                  <!-- <br /> -->
                  <select
                    class="form-control"
                    v-model="filters.deliveryLocation"
                    name=""
                    id=""
                  >
                    <option value="">None</option>
                    <option
                      v-for="(location, index) in locations"
                      :key="index"
                      :value="location"
                    >
                      {{ location }}
                    </option>
                  </select>
                  <!-- </div>
                  <div class="col-6">
                    <label for="">Sort By</label>
                    <select name="" id="" class="form-control">
                      <option value="">None</option>
                      <option value="name">Name</option>
                      <option value="capacity">Capacity</option>
                      <option value="price">Price</option>
                    </select>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["category"],
  data() {
    return {
      isFilter: true,
      minPrice: "",
      maxPrice: "",
      categorySelect: "",
      locations: [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "FCT - Abuja",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara",
      ],
      filters: {
        minPrice: "",
        maxPrice: "",
        minCapacity: "",
        maxCapacity: "",
        deliveryLocation: "",
        sort: "",
      },
    };
  },
  computed: {
    categoryData() {
      switch (this.category) {
        case "bundle":
          return "Complete Solution";
          break;
        case "all":
          return "All Products";
          break;
        case "package":
          return "Special Offers";
          break;
        case "":
          return "All Products";
          break;
        default:
          return this.category;
          break;
      }
    },
    categoryImage() {
      switch (this.category) {
        case "solar panel":
          return "/assets/images/solar.png";
          break;
        case "inverter":
          return "/assets/images/inverterb.png";
          break;
        case "battery":
          return "/assets/images/batterycateg.png";
          break;
        case "bundle":
          return "/assets/images/complete.png";
          break;
        default:
          return "/assets/images/solar.png";
          break;
      }
    },
  },
  watch: {
    "$route.query.type": function () {
      this.setCategory();
    },
    filters: {
      handler() {
        // console.log("Watch Changed");
        // console.log(val);
        this.$store.commit("filters", this.filters);
        // var yo = this.$store.getters.filters;
        // console.log(yo);
        // console.log("EOL");
      },
      deep: true,
    },
  },
  mounted() {
    this.setCategory();
  },
  methods: {
    setCategory() {
      this.categorySelect = this.category;
      if (this.category == "all") {
        this.categorySelect = "";
      }
      if (this.$route.query.type) {
        this.categorySelect = this.$route.query.type;
        this.selectChange();
      } else {
        this.categorySelect = "";
      }
    },
    selectChange() {
      this.$store.commit("categorySelect", this.categorySelect);
    },
    addToFilters() {
      // console.log("Logged");
      this.filters.minPrice = this.minPrice;
      this.filters.maxPrice = this.maxPrice;
    },
    closeFilter() {
      this.minPrice = "";
      this.maxPrice = "";
      this.filters = {
        minPrice: "",
        maxPrice: "",
        minCapacity: "",
        maxCapacity: "",
        deliveryLocation: "",
      };
      // this.categorySelect = "";
      this.selectChange();
      this.isFilter = false;
      window.scrollTo(0, 0);
    },
    openFilter() {
      this.isFilter = true;
      var filts = document.getElementById("Filters");
      // console.log(filts);
      // filts.scrollIntoView();
    },
  },
};
</script>
<style lang="scss" scoped>
.cat-img {
  height: 300px;
}
.category-select {
  select {
    option {
      color: black;
    }
  }
}

.filters {
  text-align: left;
  padding-top: 60px;
  .form-control:focus {
    border-color: #255e1388;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.close {
  width: 100%;
  /* text-align: right; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .cl {
    display: inline-block;
    /* font-size: 2rem; */
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 20px;
    /* transform: rotate(90deg); */
  }
}
.openBtn {
  text-align: left;
  .cl {
    text-decoration: underline;
    font-size: 1rem;
    display: inline-block;
    margin-top: 20px;
    cursor: pointer;
  }
}
</style>
